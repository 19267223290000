<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="512"
    height="512"
  >
    <path
      d="M18.147,7.191c-.44-.098-.805-.377-1-.766-1.615-3.224-5.103-4.943-8.675-4.279-3.135,.582-5.669,3.081-6.306,6.218-.19,.933-.218,1.873-.083,2.796,.083,.567-.058,1.073-.387,1.387C.604,13.592,.001,14.996,0,16.5c0,3.136,2.364,5.5,5.5,5.5h10.736c4.13,0,7.611-3.234,7.758-7.211,.134-3.612-2.325-6.808-5.847-7.598Zm-1.911,13.809H5.5c-2.607,0-4.5-1.893-4.5-4.499,.001-1.229,.495-2.376,1.388-3.231,.566-.54,.815-1.361,.685-2.254-.118-.808-.093-1.633,.074-2.452,.556-2.742,2.77-4.926,5.508-5.435,.448-.083,.895-.123,1.335-.123,2.637,0,5.052,1.45,6.263,3.867,.33,.659,.941,1.131,1.676,1.295,3.051,.685,5.182,3.453,5.066,6.584-.128,3.445-3.16,6.248-6.759,6.248Zm-.969-6.854c.195,.195,.195,.512,0,.707l-2.707,2.707c-.258,.257-.588,.381-.925,.412-.044,.013-.087,.027-.135,.027s-.091-.015-.135-.027c-.337-.031-.668-.155-.925-.412l-2.707-2.707c-.195-.195-.195-.512,0-.707s.512-.195,.707,0l2.561,2.561v-7.207c0-.276,.224-.5,.5-.5s.5,.224,.5,.5v7.207l2.561-2.561c.195-.195,.512-.195,.707,0Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'cloudDownload'
};
</script>

<style lang="scss" scoped>
svg {
  fill: #704ad1;
  overflow: hidden;
  vertical-align: middle;
  transform: rotateZ(0deg);
  height: 25px;
  width: 25px;
}
</style>
